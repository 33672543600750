/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
import {
  Box,
  Container,
  Image,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  RadioGroup,
  Stack,
  Icon,
} from '@chakra-ui/react';
import { Link, graphql } from 'gatsby';
import { IoFilter } from 'react-icons/io5';
import SEO from '../components/seo';
import Layout from '../components/layout';
// import CustomButton from '../components/custom-button';
import '../styles/media.scss';

const Media = (props) => {
  const { data } = props;
  const initRef = useRef();
  const yearsList = [];
  data.allWpPost.edges.forEach((post) => {
    yearsList.push(new Date(post.node.date).getFullYear());
  });
  const uniqueResult = [
    ...new Set(
      yearsList.sort(function desc(a, b) {
        return b - a;
      })
    ),
  ];
  const [activeYear, setActiveYear] = useState('Year');
  const [postsList, setPostsList] = useState(data.allWpPost.edges);
  const handleFilters = (year) => {
    const foundPost = data.allWpPost.edges.filter(
      (node) => node.node.date.slice(node.node.date.length - 4) === year
    );
    if (foundPost) {
      setPostsList(foundPost);
    }
    setActiveYear(year);
  };
  return (
    <Layout>
      <SEO
        title="Media About Nutrient Solutions and Crystal Green Fertilzer | Ostara"
        description="Explore the latest media features on Crystal Green fertilizer and our nutrient recovery technology to learn how we're solving nutrient challenges around the world. "
        keywords="Ostara Media"
        image="https://pacemediafiles.s3.us-west-2.amazonaws.com/ostara/ostara_logo.png"
      />
      <main>
        <Box className="media-articles-container">
          <Container className="media-heading" maxW="container.lg">
            <h1 className="media-heading--title">Media</h1>
            <Popover
              className="media-heading--filter"
              initialFocusRef={initRef}
            >
              {({ onClose }) => (
                <>
                  <PopoverTrigger>
                    <Button className="media-heading--filter-btn">
                      <Icon as={IoFilter} boxSize={6} />
                      <h4>{activeYear}</h4>
                    </Button>
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />

                      <PopoverCloseButton />
                      <PopoverBody>
                        <RadioGroup onChange={handleFilters}>
                          <Stack direction="column">
                            {uniqueResult.map((year) => (
                              <>
                                <input
                                  name="year"
                                  value={year}
                                  type="radio"
                                  id={year}
                                  onChange={(e) =>
                                    handleFilters(e.target.value)
                                  }
                                />
                                <label onClick={onClose} htmlFor={year}>
                                  {year}
                                </label>
                              </>
                            ))}
                          </Stack>
                        </RadioGroup>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </>
              )}
            </Popover>
          </Container>
          <Container className="media-articles" maxW="container.lg">
            {postsList && postsList.length > 0
              ? postsList.map((item) => (
                  <Link to={`/media/${item.node.slug}`} key={item.node.id}>
                    <div className="media-articles--item">
                      {item.node.featuredImage ? (
                        <Image
                          placeholder="transparent"
                          src={item.node.featuredImage.node.sourceUrl}
                          alt="Ostara logo"
                          width={100}
                          height={100}
                        />
                      ) : null}
                      <div className="document-title">
                        <div className="document-tags">
                          <h5>{item.node.title}</h5>

                          <p>{item.node.date}</p>
                        </div>
                      </div>
                      <Link to={`/media/${item.node.slug}`}>Read</Link>
                    </div>
                  </Link>
                ))
              : ''}
          </Container>
          {/* <CustomButton maxW={230} to="/product" marginTop={10}>
            LOAD MORE
          </CustomButton> */}
        </Box>
      </main>
    </Layout>
  );
};

export default Media;
export const pageQuery = graphql`
  query {
    allWpPost {
      edges {
        node {
          id
          slug
          title
          excerpt
          date(formatString: "MMM DD YYYY")
          tags {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;
